<template>
  <v-form v-model="valid" @submit.stop.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ $i18n.translate("New Promotion Invitation") }}
      </v-card-title>
      <v-card-text>
        <ApiError :errors="errors" />
        <v-form v-model="valid" @submit.stop.prevent="onSubmit" />
        <v-row>
          <v-col>
            <PromotionSimpleField
              v-model="form.selectedPromotion"
              class="required"
              :items="eligiblePromotions"
              :loading="isLoadingPromotion"
              :rules="rules.promotion"
              :error-messages="$error.getValidationError(errors, 'promotion')"
              :search-input.sync="searchInput"
              :search-filters="searchFilters"
              label="Eligible Promotions"
              status="Active"
              show-key
              showCustomNoData
            />
          </v-col>
        </v-row>
        <v-row v-if="!enrollNewParticipant">
          <v-col cols="10">
            <ParticipantField
              dense
              v-model="form.selectedParticipant"
              :preventIds="[selectedParticipant.id]"
              :label="$i18n.translate('Participant to invite')"
              :rules="rules.participant"
              :error-messages="$error.getValidationError(errors, 'participant')"
              class="required"
            />
          </v-col>
          <v-col cols="2">
            <v-btn color="primary" @click="onInviteToNewParticipant()" :disabled="!form || !form.selectedPromotion">
              <span> {{ $i18n.translate("New") }}</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-container v-if="enrollNewParticipant">
          <v-row>
            <v-col cols="6">
              <v-text-field
                name="firstName"
                :label="$i18n.translate('First Name')"
                id="firstName"
                v-model="form.selectedParticipant.user.firstName"
                :rules="rules.firstName"
                :error-messages="$error.getValidationError(errors, 'firstName')"
                @input="$error.clearValidationError(errors, 'firstName')"
                class="required"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                name="lastName"
                :label="$i18n.translate('Last Name')"
                id="lastName"
                v-model="form.selectedParticipant.user.lastName"
                :rules="rules.lastName"
                :error-messages="$error.getValidationError(errors, 'lastName')"
                @input="$error.clearValidationError(errors, 'lastName')"
                class="required"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <EmailField
                id="email"
                name="email"
                v-model="form.selectedParticipant.user.email"
                label="Email Address"
                :rules="rules.email"
                :error-messages="$error.getValidationError(errors, 'email')"
                @input="$error.clearValidationError(errors, 'email')"
                class="required"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-row>
          <v-col cols="12">
            <EmailField name="ccEmail" id="ccEmail" label="CC Email" v-model="form.ccEmail" :notRequired="true" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              type="number"
              label="Limit Submission"
              name="limitSubmissions"
              id="limitSubmissions"
              v-model="form.limitSubmissions"
              :rules="rules.limitSubmissions"
              :error-messages="$error.getValidationError(errors, 'limitSubmissions')"
              @input="$error.clearValidationError(errors, 'limitSubmissions')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <DateTimePickerField
              v-model="form.effectiveDate"
              :label="$i18n.translate('Effective Date')"
              defaultTime="00:00"
            />
          </v-col>
          <v-col cols="6">
            <DateTimePickerField
              v-model="form.expirationDate"
              :label="$i18n.translate('Expiration Date')"
              defaultTime="23:59"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="onCancel()" :loading="loading">{{ $i18n.translate("Cancel") }}</v-btn>
        <v-btn color="primary" :disabled="!valid" @click="onSubmit()" :loading="loading">
          {{ $i18n.translate("Submit") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import DateTimePickerField from "@/gapp-components/components/fields/DateTimePickerField.vue";
import EmailField from "@/gapp-components/components/fields/EmailField.vue";
import ParticipantField from "@/gapp-components/components/fields/ParticipantField.vue";
import ApiError from "@/gapp-components/components/display/ApiError.vue";
import PromotionSimpleField from "@/gapp-components/components/fields/PromotionSimpleField.vue";
import { mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  components: { ApiError, ParticipantField, EmailField, DateTimePickerField, PromotionSimpleField },
  name: "PromotionInvitationSubmissionForm",
  props: {
    value: Object,
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valid: true,
      isLoadingPromotion: false,
      enrollNewParticipant: false,
      searchInput: "",
      form: {},
      rules: {
        firstName: [v => !!v || "First Name is required"],
        lastName: [v => !!v || "Last Name is required"],
        email: [v => !!v || "E-mail is required"],
        limitSubmissions: [v => !(v && v.length > 0) || v >= 1 || "Limit Submission must be a positive number"],
        promotion: [v => !!v || "Promotion is required"],
        participant: [v => !!v || "Participant is required"]
      },
      errors: {},
      eligiblePromotions: [],
      promotionsFetched: 5,
      publishDateBefore: moment
        .tz([moment().year(), moment().month(), moment().date(), 0, 0, 0], "America/New_York")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ")
    };
  },
  computed: {
    ...mapGetters(["selectedParticipant"]),
    isRequestor() {
      return this.selectedParticipant.participantType.participantTypeKey === "1000";
    },
    searchFilters() {
      return {
        publishDateBefore: this.publishDateBefore,
        invitationOnly: true,
        promotionInvitationParticipantRequester: this.isRequestor ? { id: this.selectedParticipant.id } : null
      };
    }
  },
  methods: {
    onInviteToNewParticipant() {
      this.enrollNewParticipant = true;
      this.form.selectedParticipant = {
        user: {},
        address: {
          country: { name: this.form.selectedPromotion.country.name }
        }
      };
    },
    onCancel() {
      this.form.selectedParticipant = null;
      this.$emit("onCancel");
    },
    onSubmit() {
      this.$emit("input", this.form);
      this.$emit("onSubmit");
    }
  }
};
</script>
