<template>
  <v-combobox
    v-if="reload"
    :value="selectedItem"
    :loading="isLoading"
    :items="items"
    :search-input.sync="search"
    :item-text="render"
    :label="label"
    :hide-no-data="!showCustomNoData"
    @input="onInput"
    @click:clear="onClear"
    v-bind="$attrs"
    v-on="$listeners"
    item-value="id"
    clearable
    return-object
    no-filter
    ref="combobox"
  >
    <template v-slot:selection="{ item }">
      <slot name="selectedItemDescription" :item="item">{{ render(item) }}</slot>
    </template>

    <template v-slot:item="{ item }">
      <slot name="itemDescription" :item="item">{{ render(item) }}</slot>
    </template>

    <template v-slot:append-item>
      <v-list-item disabled v-if="showBottomMessage">
        <v-list-item-content>
          <v-list-item-title>
            {{ $i18n.translate("Type to filter more records...") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            {{ $i18n.translate("No records available") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
import ApiService from "@/gapp-components/services/api.service";

export default {
  name: "PromotionSimpleField",
  props: {
    value: [Object, String],
    preventIds: Array,
    typeKey: String,
    showKey: {
      type: Boolean,
      default: false,
      required: false
    },
    status: { type: String, required: false },
    label: {
      type: String,
      default: ""
    },
    showCustomNoData: {
      type: Boolean,
      default: false
    },
    fetchSize: {
      type: Number,
      default: 9
    },
    searchFilters: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    debounceTimeout: null,
    showBottomMessage: false,
    selectedItem: {},
    isLoading: false,
    search: null,
    items: [],
    rules: [
      v => {
        if (!!v && !v.id) {
          return "Invalid selection";
        } else {
          return true;
        }
      }
    ],
    reload: true
  }),
  watch: {
    value(val) {
      this.selectedItem = val;
    },
    search(val) {
      this.debouncedFetchItems(val);
    }
  },
  methods: {
    render(item) {
      if (!item) return "";
      return `${this.showKey && item.promotionKey ? `${item.promotionKey} - ` : ""}${item.name || ""}`;
    },
    onInput(obj) {
      if (obj && obj.id) {
        this.$emit("input", obj);
      }
    },
    onClear() {
      this.search = "";
      this.fetchItems();
    },
    clear() {
      this.reload = false;
      this.$nextTick(() => {
        this.reload = true;
      });
    },
    debouncedFetchItems(keyword) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.fetchItems(keyword);
      }, 400);
    },
    async fetchItems(keyword = "") {
      this.isLoading = true;
      let filters = {
        keyword,
        isEffective: this.status
      };
      if (this.searchFilters && Object.keys(this.searchFilters).length > 0) {
        filters = { ...filters, ...this.searchFilters };
      }

      try {
        const { data } = await ApiService.post(`/api/promotions/search/simple?size=${this.fetchSize}`, filters);
        let fullItems = data.content;
        if (this.preventIds && this.preventIds.length > 0) {
          fullItems = fullItems.filter(cur => !this.preventIds.includes(cur.id));
        }
        this.items = fullItems;
        this.showBottomMessage = fullItems.length === this.fetchSize;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    }
  },
  mounted() {
    this.selectedItem = this.value;
    this.search = "";
    this.fetchItems();
  }
};
</script>
