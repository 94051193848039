<template>
  <PromotionInvitationTable />
</template>

<script>
import PromotionInvitationTable from "../../../gapp-components/components/tables/PromotionInvitationTable.vue";
export default {
  components: { PromotionInvitationTable },
  name: "PromotionInvitations",
  metaInfo: {
    title: "Promotion Invitations"
  },

  mounted() {
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      {
        text: "Admin"
      },
      { text: "Promotion Invitations" }
    ]);
  }
};
</script>
